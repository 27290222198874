import React, { lazy } from 'react'
// import {} from './Hotel'
const Home = lazy(() =>
  import('./Home').then(module => ({
    default: module.Home,
  }))
)
const FacilitiesDetail = lazy(() =>
  import('./FacilitiesDetail').then(module => ({
    default: module.FacilitiesDetail,
  }))
)
const RatingReview = lazy(() =>
  import('./RatingReview').then(module => ({
    default: module.RatingReview,
  }))
)
const HotelDetail = lazy(() =>
  import('./HotelDetail').then(module => ({
    default: module.HotelDetail,
  }))
)
const RoomTypeDetail = lazy(() =>
  import('./RoomTypeDetail').then(module => ({
    default: module.RoomTypeDetail,
  }))
)
const BookingSummary = lazy(() =>
  import('./BookingSummary').then(module => ({
    default: module.BookingSummary,
  }))
)
const NotAvailable = lazy(() =>
  import('./NotAvailable').then(module => ({
    default: module.NotAvailable,
  }))
)
// const RoomTypeDetail = lazy(() =>
//   import('./Hotel').then(module => ({
//     default: module.RoomTypeDetail,
//   }))
// )
// const BookingSummary = lazy(() =>
//   import('./Hotel').then(module => ({
//     default: module.BookingSummary,
//   }))
// )
// const RatingReview = lazy(() =>
//   import('./Hotel').then(module => ({
//     default: module.RatingReview,
//   }))
// )
// const BookingDetail = lazy(() =>
//   import('./BookingDetail/BookingDetail').then(module => ({
//     default: module.BookingDetail,
//   }))
// )
// const ERegistration = lazy(() =>
//   import('./CheckIn/ERegistration').then(module => ({
//     default: module.ERegistration,
//   }))
// )
// const CheckInSummary = lazy(() =>
//   import('./CheckIn/CheckInSummary').then(module => ({
//     default: module.CheckInSummary,
//   }))
// )
// const InvoiceDocument = lazy(() =>
//   import('../Services/InvoiceDocument').then(module => ({
//     default: module.InvoiceDocument,
//   }))
// )
export const BOOKING_PATH = {
  ROOT: '/',
  PAYMENT_REDIRECT: '/redirect/:status',
  FACILITIES_DETAIL: '/facilities-detail',
  HOTEL_RATING_REVIEW: '/rating-review',
  HOTEL_DETAIL: '/rooms',
  ROOM_TYPE_DETAIL: '/rooms/room-type-detail',
  BOOKING_SUMMARY: '/rooms/summary',
  NOT_AVAILABLE: '/not-available'
  // BOOKING_LIST: '/my-booking',
  // BOOKING_LIST_FROM_SERVICE: '/booking',
  // BOOKING_DETAIL: '/booking/detail',
  // BOOKING_INVOICE: '/booking/detail/invoice',
  // BOOKING_CHECKIN: '/booking/detail/checkin',
  // BOOKING_CHECKIN_SUMMARY: '/booking/detail/checkin/summary',
  // HOTEL_LIST: '/booking/hotel',
  // HOTEL_ROOMTYPE_DETAIL: '/booking/hotel/detail/roomtype-detail',
  // HOTEL_RATING_REVIEW: '/booking/hotel/detail/rating-review',
}
const HomeRoutes = [
  {
    props: { exact: true, path: BOOKING_PATH.ROOT },
    component: <Home />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.PAYMENT_REDIRECT },
    component: <Home />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.FACILITIES_DETAIL },
    component: <FacilitiesDetail />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.HOTEL_RATING_REVIEW },
    component: <RatingReview />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.HOTEL_DETAIL },
    component: <HotelDetail />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.ROOM_TYPE_DETAIL },
    component: <RoomTypeDetail />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.BOOKING_SUMMARY },
    component: <BookingSummary />,
  },
  {
    props: { exact: true, path: BOOKING_PATH.NOT_AVAILABLE },
    component: <NotAvailable />,
  }
  // {
  //   props: { exact: true, path: BOOKING_PATH.BOOKING_LIST },
  //   component: <Booking />,
  // },
  // {
  //   props: { exact: true, path: BOOKING_PATH.BOOKING_LIST_FROM_SERVICE },
  //   component: <Booking />,
  // },
  // {
  //   props: { exact: true, path: BOOKING_PATH.BOOKING_LIST_PAYMENT },
  //   component: <Booking />,
  // },
  // {
  //   props: { exact: true, path: BOOKING_PATH.HOTEL_LIST },
  //   component: <HotelList />,
  // },

  // {
  //   props: { exact: true, path: BOOKING_PATH.HOTEL_ROOMTYPE_DETAIL },
  //   component: <RoomTypeDetail />,
  // },
  // {
  //   props: { exact: true, path: BOOKING_PATH.HOTEL_RATING_REVIEW },
  //   component: <RatingReview />,
  // },
  // {
  //   props: { exact: true, path: BOOKING_PATH.BOOKING_DETAIL },
  //   component: <BookingDetail />,
  // },
  // {
  //   props: { exact: true, path: BOOKING_PATH.BOOKING_CHECKIN },
  //   component: <ERegistration />,
  // },
  // {
  //   props: { exact: true, path: BOOKING_PATH.BOOKING_CHECKIN_SUMMARY },
  //   component: <CheckInSummary />,
  // },

  // {
  //   props: { exact: true, path: BOOKING_PATH.BOOKING_INVOICE },
  //   component: <InvoiceDocument Mode={'Booking'} />,
  // },
]

export default HomeRoutes
