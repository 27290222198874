import { CommonDialog, CopyRight, MainHeader, MainWrapper } from '@ifca-ui/neumorphism'
import AppContext, { AppContextProps } from 'containers/Context/Context'
import { PUBLIC_PATH } from 'containers/Module/Auth/Routes'
import { useHotelDetailsQuery } from 'generated/graphql'
import React, { ReactNode, useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import HotelX from 'assets/images/app-logo-b.svg'
import { Button } from '@material-ui/core'
interface Props {
  children?: ReactNode
}

export const GuestLayout = ({ children }: Props) => {
  const history = useHistory()
  const [checkPDPA, setCheckPDPA] = useState(false)
  const [openTerm, setOpenTerm] = useState(false);
  const { RootState, RootDispatch } = useContext<AppContextProps>(AppContext)
  const location = useLocation()

  const localState = location.state as any
  const handleCloseTerm = () => {
    setOpenTerm(false);
  };
  const handleClosePDPA = () => {
    setCheckPDPA(false);
  };
  const Hotel = localStorage?.getItem('HotelID')

  let url = new URL(window.location.href)
  const {
    loading,
    data: { HotelDetails } = { HotelDetails: null },
  } = useHotelDetailsQuery({
    variables: {
      // CheckIn: state.checkInDate,
      // CheckOut: state.checkOutDate,
      OnlineBookingURL: url.hostname,
      // HotelID: '271b1802-e743-11ea-adc1-0242ac120002',
    },
  })

  // const {
  //   data: { loggedInGuestProfile } = { loggedInGuestProfile: null },
  // } = useLoggedInGuestProfileQuery({
  //   fetchPolicy: 'network-only',
  //   onError: error => {
  //     // eslint-disable-next-line array-callback-return
  //     error.graphQLErrors.map(({ message }) => {
  //       RootDispatch({
  //         type: 'OnSnackBar',
  //         payload: {
  //           ...RootState.OnSnackBar,
  //           Open: true,
  //           Message:
  //             message === 'Not Authenticated'
  //               ? 'Login session expired, Please login again.'
  //               : message,
  //           onClick: () =>
  //             RootDispatch({
  //               type: 'CloseSnackBar',
  //               payload: {},
  //             }),
  //         },
  //       })
  //       if (message === 'Not Authenticated') {
  //         setTimeout(() => {
  //           RootDispatch({
  //             type: 'CloseSnackBar',
  //             payload: {},
  //           })
  //           history.push(PUBLIC_PATH.LOGIN)
  //           localStorage.clear()
  //         }, 2000)
  //       }
  //     })
  //   },
  // })

  const pdpaDialog = (
    <>
      <CommonDialog
        open={checkPDPA}
        onClose={handleClosePDPA}
        sections={{
          content: (
            <>
              <div className="m-b-8 title color-blue-2">
                PRIVACY POLICY
              </div>
              <div
                style={{ fontSize: 14, padding: 3 }}
                dangerouslySetInnerHTML={{
                  __html: HotelDetails?.PDPA,
                }}
              />
            </>
          ),
          footer: {
            children: (
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="action-btn"
                onClick={() => {
                  setCheckPDPA(false)
                }}
              >
                Close
              </Button>
            )
          }
        }}
      />
    </>
  )

  const tncDialog = (
    <>
      <CommonDialog
        open={openTerm}
        onClose={handleCloseTerm}
        sections={{
          content: (
            <>
              <div className="m-b-8 title color-blue-2">
                TERMS AND CONDITIONS
              </div>
              <div
                style={{ fontSize: 14, padding: 3 }}
                dangerouslySetInnerHTML={{
                  __html: HotelDetails?.OnlineTnC,
                }}
              />
            </>
          ),
          footer: {
            children: (
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="action-btn"
                onClick={() => {
                  setOpenTerm(false)
                }}
              >
                Close
              </Button>
            )
          }
        }}
      />
    </>
  )

  return (
    <MainWrapper>
      {' '}
      <MainHeader
        sections={{
          header: RootState?.HeaderSection?.header,
          left: RootState?.HeaderSection?.left,
          right: RootState?.HeaderSection?.right,
        }}
      />
      {children}
      <CopyRight title={<><img src={HotelX} alt="logo" width={50} />
      <span className=" flex-width"></span>
        <span 
          style={{ textDecoration: "underline" }}
          onClick={() => {
            console.log('clicked')
            setOpenTerm(true)
          }}
        >
          HOTEL TERMS & CONDITIONS
        </span>
        <span style={{ paddingLeft: '3px' }}>|</span>
        <span
          style={{ textDecoration: "underline", paddingLeft: '3px' }}
          onClick={() => {
            console.log('clicked')
            setCheckPDPA(true)
          }}
        >
          PRIVACY POLICY
        </span>
      </>} />

      {tncDialog}
      {pdpaDialog}
    </MainWrapper>
  )
}
